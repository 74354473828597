@import "./stylesheet/style.scss";


.styles_react-code-input__CRulA > input {
    border: solid 1px !important;
    border-right: solid 1px !important;
    /* box-sizing: border-box; */
    -webkit-appearance: initial !important;
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%) !important;
    border-color: #565656 !important;
    border-radius: 15px !important;
    text-align: center !important;
    font-family: "Outfit", sans-serif !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #ffffff !important;
    width: 68px !important;
    height: 60px !important;
    caret-color: #ffffff !important;
    padding: 10px 20px !important;
}
  .styles_react-code-input__CRulA {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  @media screen and (max-width: 1440px) {
    .styles_react-code-input__CRulA > input {
        width: 55px !important;
        height: 50px !important;
      }
  }
  
  @media screen and (max-width:1199px) {
    .styles_react-code-input__CRulA>input {
        width: 46px !important;
        height: 44px !important;
    }
  }
  
  @media screen and (max-width:600px) {
    .styles_react-code-input__CRulA>input {
        padding: 10px 11px !important;
      }
      .styles_react-code-input__CRulA>input {
        width: 44px !important;
        height: 42px !important;
      }
  }
  
  @media screen and (max-width:375px) {
    .styles_react-code-input__CRulA>input {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
    color: #b1afcd;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  color: #b1afcd;
}
.loading{
  font-size: 13px;
  color: #9b96b0;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.page-item {
  display: inline-block;
  margin: 0 4px;
  border-radius: 6px;
}

.page-link {
  display: block;
  padding: 6px 12px !important;
  text-align: center;
  border: 1px solid #312f62;
  border-radius: 6px;
}

.page-link:hover {
  background-color: #18173d;
  color: #b1afcd;
  border: 1px solid #312f62;
}

.activepage {
  background-color: #0dcaf0;
  color: #fff;
  border: 1px solid #312f62;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  border: 1px solid #312f62 !important;
}
iframe {
  pointer-events: none;
}
.form-control:disabled {
    background-color: #141133;
    border-color: #312f62;
    opacity: 1;
}
.note_msg{
    padding: 10px 16px;
    background: #231e4e;
    border-radius: 8px;
}
.text-tx{
  color: #11081f ;
}
.export_btn{
  padding: 10px 25px !important;
}