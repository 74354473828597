html {
  --color-white: #ffffff;
  --color-main-blue: #151133;
  --color-Mine-Shaft: #3e3e3e;
  --color-black: #000000;
  --color-jaguar: #0a0118;
  --color-ButterflyBush: #5651a5;
  --color-AmethystSmoke: #9b96b0;
  --color-codegray: #141133;
  --color-logan: #b1afcd;
  --color-Scorpion: #565656;
  --color-Kimberly: #6f6c99;
  --color-PictonBlue: #53b9ea;
  --color-PortGore: #1a1840;
}

html.transition,
html.transition *,
html.transition *:before,
html.transition *:after {
  transition: all 750ms !important;
  transition-delay: 0 !important;
}

.text-white {
  color: var(--color-white);
}
.text-AmethystSmoke {
  color: var(--color-AmethystSmoke);
}
.text-logan {
  color: var(--color-logan);
}
.text-PictonBlue {
  color: var(--color-PictonBlue);
}
