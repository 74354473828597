// Dashboard layout

// ---------------------------------------------

@mixin transitionAll() {
  transition: all 0.3s ease-out;
}
.header-dashboard {
  .navbar {
    left: auto;
    right: auto;
    width: 100%;
  }
  .notification-menu {
    width: 320px;
    left: -200px;
    background-color: var(--color-main-blue);
    border: 1px solid var(--color-logan);
    ul {
      li {
        background-color: var(--color-main-blue);
        color: var(--color-logan);
        padding: 0.5rem 1rem;
        border-radius: 0.3rem;
        &:hover,
        &:focus,
        &.active,
        &:active {
          background-color: var(--color-logan);
          color: var(--color-black);
        }
      }
    }
  }
}
.myreal-container {
  background-color: var(--color-codegray);
  display: flex;
  width: 100%;
  height: 100%;
}
.sidebar {
  flex: 1 0 280px;
  padding: 4rem 2rem;
  background: linear-gradient(
    180deg,
    rgba(38, 34, 80, 0.65) 0%,
    rgba(27, 25, 66, 0.85) 57.44%,
    #17163b 100%
  );
  height: 100%;
  position: fixed;
  // overflow-y: scroll;
  width: 280px;
  @include transitionAll();
  &.is-collapsed {
    transform: translateX(-100%);
  }
  .nav-link {
    padding: 1rem 0.57rem;
    color: var(--color-Kimberly);
    font-size: 18px;
    font-weight: 400;
    font-family: var(--font-family-roboto);
    position: relative;

    &:hover,
    &:focus,
    &.active,
    &:active {
      color: var(--color-PictonBlue);
      &::after {
        content: "";
        position: absolute;
        left: -39px;
        top: 23px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: var(--color-PictonBlue);
        background: var(--color-PictonBlue);
        border: 1px solid var(--color-PictonBlue);
        box-shadow: 0px 0px 18px 4px var(--color-PictonBlue);
      }
    }
  }
}
.dash-sidebar-mobile {
  background: linear-gradient(
    180deg,
    rgba(38, 34, 80, 1) 0%,
    rgba(27, 25, 66, 1) 57.44%,
    #17163b 100%
  );
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  width: 280px;
  @include transitionAll();
  &.is-collapsed {
    transform: translateX(-100%);
  }
  .nav-link {
    padding: 1rem 0.57rem;
    color: var(--color-Kimberly);
    font-size: 18px;
    font-weight: 400;
    font-family: var(--font-family-roboto);
    position: relative;
    ::before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      height: 10px;
      width: 10px;
      background-color: var(--color-PictonBlue);
    }
    &:hover,
    &:focus,
    &.active,
    &:active {
      color: var(--color-PictonBlue);
      :before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 10px;
        width: 10px;
        background-color: var(--color-PictonBlue);
      }
    }
  }
}
.main-content {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  @include transitionAll();
  margin-left: 280px;
  &.is-full-width {
    margin-left: 280px;
  }
}
@include media-breakpoint-down(lg) {
  .main-content {
    margin-left: 0;
    &.is-full-width {
      margin-left: 0;
    }
  }
}
.myreal-container-close {
  display: flex;
  width: 100%;
  height: 100%;
  .sidebar {
    transform: translateX(-100%);
  }
  .main-content {
    margin-left: 0;
  }
}

// DashbOrd page designe csss

.tokenaddet {
  background: linear-gradient(
    135deg,
    #40ddff 0%,
    #14bae3 19.24%,
    #13b1e6 68.64%,
    #11aadf 81.77%,
    #0b98c5 100%
  );
  border-radius: 10px;
}
.reffrealcoed {
  background: #141133;
  border: 1px solid #312f62;
  border-radius: 6px;
}
.data-cards {
  border: 1px solid var(--color-Mine-Shaft);
  background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
  border-radius: 15px;
  transition: all 3s ease-in-out;
  &:hover,
  &:focus {
    background: linear-gradient(135deg, #e3507a 0%, #a54bc6 100%);
    border-radius: 10px;
  }
}

// SignUp Layouts

.signup-layout {
  background-image: url(../assets/images/Signin-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .auth-main {
    position: absolute;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .sign-card {
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
    border-radius: 15px;
    border: 1px solid var(--color-Scorpion);
  }
  .form-control,
  .form-select {
    border-color: var(--color-Scorpion);
    font-family: var(--font-family-roboto);
    height: 60px;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: var(--color-logan);
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
    border-radius: 15px;
  }
}

// Progress bar chanrt

.customprogres {
  .progress {
    background-color: transparent;
    /* border-radius: 20px; */
    position: relative;
    margin: 0;
    height: 50px;
    width: 100%;
    padding: 40px 0 0;
    &::before {
      content: " ";
      height: 10px;
      width: 100%;
      background-color: var(--color-PortGore);
      position: absolute;
      top: 40px;
      border-radius: 30px;
    }
  }

  .progress-done {
    box-shadow: 0 3px 3px -5px #f2709c, 0 2px 5px #f2709c;
    background: linear-gradient(270.02deg, #d7911d -4.32%, #ffd599 103.24%);
    border-radius: 6px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
    width: 0;
    opacity: 0;
    transition: 1s ease 0.3s;
    position: relative;
    padding-right: 5px;

    &::before {
      content: attr(data-width);
      height: 19px;
      width: 80px;
      border-radius: 30px;
      background: linear-gradient(
        180deg,
        rgba(51, 47, 103, 0.443218) 2.1%,
        rgba(61, 60, 125, 0.401268) 98.26%
      );
      box-shadow: 0px 12px 14px rgba(32, 30, 66, 0.506567);
      backdrop-filter: blur(9.27735px);
      /* Note: backdrop-filter has minimal browser support */

      border-radius: 14.5px;
      position: absolute;
      top: -26px;
      right: 0;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      padding: 1px;
    }
  }
  .progress-one {
    background: linear-gradient(270.02deg, #d7911d -4.32%, #ffd599 103.24%);
  }
  .progress-two {
    background: linear-gradient(270.02deg, #ff409a -4.32%, #e3507a 103.24%);
  }
  .progress-three {
    background: linear-gradient(90.06deg, #50e3c2 -0.08%, #0b98c5 104.3%);
  }
  .progress-four {
    background: linear-gradient(90.06deg, #c1e98f -0.08%, #b1ff72 104.3%);
  }
}

.users-table {
  border: 1px solid var(--color-ButterflyBush);
  border-radius: 0.4rem;
}
