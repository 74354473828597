@import "layout";

body {
  background: #0a0118;
  color: white;
  font-family: var(--font-family-roboto);
}

.main-hro-container {
  background-image: url(../assets/images/hero-contain-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center 14rem;

  // padding-top: 3rem;
  // padding-bottom: 8rem;
  .hero-banner {
    margin-top: -4rem;
  }

  .home-about {
    .home-about-inner {
      background: linear-gradient(180deg, #100621 -5.5%, #0a0118 105.87%);
      border-radius: 15px;

      border: 1px solid var(--color-Mine-Shaft);
    }
  }
}

@include media-breakpoint-down(lg) {
  .main-hro-container {
    background-position: center 35rem;
  }
}

@include media-breakpoint-down(md) {
  .main-hro-container {
    background-position: center 30rem;
  }
}

.sect-title-emplty-sec {
  background-image: url(../assets/images/sectionbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding: 10rem;
}

@include media-breakpoint-down(lg) {
  .sect-title-emplty-sec {
    padding: 10rem;
  }
}

@include media-breakpoint-down(md) {
  .sect-title-emplty-sec {
    padding: 6.5rem;
  }
}

.about-footer-upsec {
  background-image: url(../assets//images/aboufooter.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center 0;
  padding: 6rem;
}

.home-service-sec {
  margin-top: -4rem;
}

.serv-cards {
  border: 1px solid var(--color-Mine-Shaft);
  background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
  border-radius: 15px;

  .serv-thumb-bg {
    background-image: url(../assets/images/serv-card-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center -0.7rem;
  }
}

.home-roadmap-sec {
  .home-road-contnr {
    background-image: url(../assets/images/roadmapbg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center -0.7rem;
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
}

.about-sec-imgsec {
  img {
    min-height: 225px;
  }
}

.contracttokenadd {
  background: linear-gradient(180deg, #5651a5 0%, #5651a5 100%);
  border-radius: 15px;
  border: 1px solid #5651a5;
}

.tokencardsg {
  .gchead {
    background: linear-gradient(180deg, #822eb0 0%, #d75c93 100%);
    border-radius: 5px;
  }
}

.gpricecard {
  border: 1px solid var(--color-Mine-Shaft);
  background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
  border-radius: 15px;
  background-image: url(../assets/images/serv-card-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center -0.7rem;
}

.gtexchangesec {
  background-image: url(../assets/images/roadmapbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center 0;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.gtexcardsing {
  background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
  border-radius: 15px;
  border: 1px solid var(--color-Mine-Shaft);

  .dropdown-gtbtn {
    border: 1px solid var(--color-Mine-Shaft);
    width: 100px;
    border-radius: 7px;
  }

  .swparrow {
    background: linear-gradient(275.18deg,
        rgba(217, 217, 217, 0.3) -4.84%,
        rgba(217, 217, 217, 0.1) 104.47%);
    border-radius: 50%;
    height: 54px;
    width: 60px;
    border: 1px solid var(--color-Mine-Shaft);
    padding-top: 11px;
  }
}

// roadmap

.main-timeline-section {
  position: relative;
  width: 100%;
  margin: auto;
  height: 300px;

  // .main-timeline-section .timeline-start,
  // .main-timeline-section .timeline-end {
  //   position: absolute;
  //   background: #a54bc6;
  //   border-radius: 100px;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 30px;
  //   height: 30px;
  // }
  .timeline-end {
    right: 0px;
  }

  .conference-center-line {
    position: absolute;
    width: 100%;
    height: 5px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border-top: 5px dotted #15174a;
  }

  .timeline-article {
    width: 20%;
    position: relative;
    min-height: 300px;
    float: right;
  }

  .timeline-article .content-date {
    position: absolute;
    top: 30%;
    left: -31%;
    font-size: 18px;

    span {
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
      font-family: var(--font-family-outfit);
    }
  }

  .timeline-article .meta-date {
    position: absolute;
    top: 50%;
    left: -31%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #a54bc6;
    border: 1px solid #a54bc6;
    box-shadow: 0px 0px 23px 7px #a54bc6;
  }

  .timeline-article .content-box {
    //   box-shadow: 2px 2px 4px 0px #c1c1c1;
    border: 0px solid #a54bc6;
    border-radius: 5px;
    //   background-color: #fff;
    width: 180px;
    position: absolute;
    top: 61%;
    left: -80px;

    ul {
      li {
        font-size: 18px;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        font-family: var(--font-family-roboto);
        color: var(--color-logan);
      }
    }
  }

  // .timeline-article-top .content-box:before {
  //   content: " ";
  //   position: absolute;
  //   left: 6%;
  //   transform: translateX(-50%);
  //   top: -20px;
  //   border: 10px solid transparent;
  //   border-bottom-color: #a54bc6;
  // }
  .timeline-article-bottom .content-date {
    top: 60%;
  }

  .timeline-article-bottom .content-box {
    top: -10%;
  }

  // .timeline-article-bottom .content-box:before {
  //   content: " ";
  //   position: absolute;
  //   left: 6%;
  //   transform: translateX(-50%);
  //   bottom: -20px;
  //   border: 10px solid transparent;
  //   border-top-color: #a54bc6;
  // }
  @media (max-width: 460px) {
    body {
      display: none;
    }
  }
}

.whitepaper-maincont {
  .subcontintr {
    background: linear-gradient(180deg, #100621 -5.5%, #0c011d 105.87%);
    border-radius: 15px;
    border: 1px solid var(--color-Mine-Shaft);

    p,
    ul,
    ol,
    li {
      text-align: justify;
      font-family: var(--font-family-roboto);
      color: var(--color-AmethystSmoke);
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    @media (max-width: 767px) {

      p,
      ul,
      ol,
      li {
        font-size: 16px;
      }
    }

    .blockqoutes {
      margin: 4rem 0;

      .quotetag {
        text-align: center;
        border: 1px solid var(--color-Mine-Shaft);
        border-radius: 2rem;
        color: var(--color-AmethystSmoke);
        background-image: url(../assets/images/roadmapbg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center 0;
        padding: 4rem 0.75rem;
        line-height: 2.25rem;
        margin: 0.25rem 0;
      }
    }

    .bottomsec {
      p {
        text-align: left;
        font-size: 16px;
      }

      a {
        font-size: 14px;
        padding-left: 5px;
        word-wrap: break-word;
      }
    }
  }
}

.whitepaperdownload-btn {
  position: fixed;
  right: 5px;
  top: 25%;
}

.withdraw_label {
  font-size: 12px;
  margin-top: 10px;
}