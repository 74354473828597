.webheader {
  background-image: url(../assets/images/Headerbg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0 35px;
  padding-top: 1rem;
  padding-bottom: 8rem;
  .navbar {
    background-color: transparent;
    .navbar-toggler {
      background-color: var(--color-ButterflyBush);
      &.navbar-toggler.collapsed {
        background-color: var(--color-white);
      }
    }
    .navbar-collapse.collapse.show {
      transition: all 1s ease-in-out;
    }
    .navbar-nav {
      .nav-link {
        color: var(--color-white);
        text-transform: capitalize;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-size: 20px;
        font-family: var(--font-family-outfit);
        font-weight: 400;
        color: var(--color-white);
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: var(--color-ButterflyBush);
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .navbar {
      .navbar-collapse.collapse.show {
        background-color: #0a0118d4;
        padding: 15px;
        // border: 1px solid var(--color-Mine-Shaft);
        margin-top: 35px;
        border-radius: 15px;
        transition: all 1s ease-in-out;
      }
      .navbar-nav {
        .nav-link {
          margin-bottom: 10px;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .webheader {
    padding-top: 0;
    padding-bottom: 8rem;
  }
}
footer {
  .footer-top {
    border-top: 1px solid var(--color-Mine-Shaft);
    border-bottom: 1px solid var(--color-Mine-Shaft);
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
  }
  .footer-bottom {
    background-color: var(--color-jaguar);
  }
}

// Roadmap verticsl

h1 span {
  font-weight: 600;
}

.verticalroadmap-sec {
  .vertical-road-contin {
    width: 80%;
    // padding: 50px 0;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
  }

  .vertical-road-contin:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 82%;
    z-index: 1;
    border: 3px dashed #a54bc6;
  }

  .timeline-block {
    width: -webkit-calc(50% + 8px);
    width: -moz-calc(50% + 8px);
    width: calc(50% + 8px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    // display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
  }

  .marker {
    width: 140px;
    height: 35px;
    border-radius: 21px;
    border: 1px solid var(--color-AmethystSmoke);
    margin-top: 10px;
    z-index: 9999;
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
    border-radius: 15px;
  }

  .timeline-block-left {
    float: left;
    direction: ltr;
    position: relative;
    &::before {
      content: " ";
      width: 100px;
      position: absolute;
      border: 3px dashed #a54bc6;
      right: 11%;
      top: 13px;
      z-index: -11;
    }
    .marker {
      margin-top: 0px;
      margin-left: 60px;
      position: absolute;
      right: -12%;
    }

    .timeline-content {
      direction: ltr;
    }
  }

  .timeline-block-right {
    float: right;
    display: flex;
    position: relative;
    &::before {
      content: " ";
      width: 100px;
      position: absolute;
      border: 3px dashed #a54bc6;
      top: 13px;
      z-index: -11;
      left: 11%;
    }
    .marker {
      margin-top: 0px;
      margin-left: -60px;
    }
  }

  .timeline-content {
    width: 70%;
    padding: 1rem;
    color: #666;
    border: 1px solid var(--color-Mine-Shaft);
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
    border-radius: 15px;
    background-image: url(../assets/images/serv-card-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center 0;
  }

  .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500;
  }

  .timeline-content span {
    font-size: 15px;
    color: #a4a4a4;
  }

  .timeline-content p {
    font-size: 13px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
  }

  @media screen and (max-width: 768px) {
    .vertical-road-contin:before {
      left: 8px;
      width: 2px;
    }
    .timeline-block {
      width: 100%;
      margin-bottom: 30px;
    }
    .timeline-block-right {
      float: none;
    }

    .timeline-block-left {
      float: none;
      direction: ltr;
    }
  }
}

// RoadMap 2 ------------------------------------------------------

.roadmap-two {
  /* The actual timeline (the vertical ruler) */
  .roadttimeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

  /* The actual roadttimeline (the vertical ruler) */
  .roadttimeline::after {
    content: "";
    position: absolute;
    width: 6px;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
    border: 3px dashed #a54bc6;
  }

  /* Container around content */
  .roadmaincont {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 35%;
    right: -64%;
    margin-bottom: 2rem;
  }

  /* The circles on the roadttimeline */
  .roadmaincont::after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    right: -44%;
    top: 15px;
    z-index: 1;
    border-radius: 100%;
    background: #a54bc6;
    border: 1px solid #a54bc6;
    box-shadow: 0px 0px 26px 9px #a54bc6;
  }

  /* Place the roadmaincont to the left */
  .roadm-left {
    left: 0;
  }

  /* Place the roadmaincont to the right */
  .roadm-right {
    left: auto;
  }

  /* Add arrows to the left roadmaincont (pointing right) */
  .roadm-left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #a54bc6;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #a54bc6;
  }

  /* Add arrows to the right roadmaincont (pointing left) */
  .roadm-right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #a54bc6;
    border-width: 10px 10px 10px 0;
    border-color: transparent #a54bc6 transparent transparent;
  }

  /* Fix the circle for roadmainconts on the right side */
  .roadm-right::after {
    left: -41%;
  }

  /* The actual content */
  .content {
    width: 100%;
    padding: 2rem;
    color: #666;
    border: 1px solid var(--color-Mine-Shaft);
    background: linear-gradient(180deg, #0a0118 0%, #160d23 100%);
    border-radius: 15px;
    background-image: url(../assets/images/serv-card-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center 0;
    min-height: 150px;
  }

  /* Media queries - Responsive roadttimeline on screens less than 600px wide */
  @media screen and (max-width: 600px) {
    /* Place the timelime to the left */
    .roadttimeline::after {
      left: 31px;
    }

    /* Full-width roadmainconts */
    .roadmaincont {
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .roadmaincont::before {
      left: 60px;
      border: medium solid #a54bc6;
      border-width: 10px 10px 10px 0;
      border-color: transparent #a54bc6 transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .roadm-left::after,
    .roadm-right::after {
      left: 24px;
      top: 27px;
    }

    /* Make all right roadmainconts behave like the left ones */
    .roadm-right {
      left: 0%;
    }
  }
}
